import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const TopicBatchDateModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [lessonData, setLessonData] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    console.log(selectedDate);
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            setLessonData(item);
            setSelectedDate(item?.date ? dayjs(item.date) : null);
            setModalBox(true);
        }
    }));

    const handleSaveNotification = async () => {
        if (!selectedDate) {
            toast.error('Please select a date!');
            return;
        }
        const formattedDate = selectedDate?.format('YYYY-MM-DD HH:mm:ss'); // Converts to datetime format

        try {
            await axios.post(`${BACKEND_SERVER_URL}/updateLessonDate`, { ...lessonData, date: formattedDate });
            toast.success(`Lesson date updated `);
            setModalBox(false);
            props.handleShowLessons();
        } catch (error) {
            toast.error('Failed to update lesson date.');
        }
    };

    return (
        <>
            <Modal onHide={() => setModalBox(false)} show={modalBox} dialogClassName="" backdrop="static">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Update Lesson Date</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)} style={{ marginTop: '-1rem', marginRight: '-1rem' }}></button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="newDate" className="form-label">New Date</label>
                        <div className="mb-3" style={{ width: '100%' }}> {/* Increased width */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ width: '100%' }}> {/* Ensures full width */}
                                    <MobileDateTimePicker
                                        id="newDate"
                                        value={selectedDate}
                                        format="DD/MM/YYYY hh:mm A"
                                        onChange={(date) => setSelectedDate(date)}
                                        ampm={true}
                                        slotProps={{ textField: { fullWidth: true } }} // Ensures full width
                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSaveNotification}>Update</button>
                    </div>
                </div>
            </Modal>

        </>
    );
});

export default TopicBatchDateModal;
