import React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';

const DateInput = ({ selected, handleChangeCallBack, labelName, inputName }) => {
    // console.log(new Date(selected))

    const selectedDate = dayjs(selected);// Parse the selected value as a Date object
    // selectedDate.setUTCHours(selectedDate.getUTCHours() + 6); // Adjust for India time zone (UTC+5)
    // selectedDate.setUTCMinutes(selectedDate.getUTCMinutes() + 30); // Adjust for India time zone (additional 30 minutes)

    return (
        <>
            <div className="col-xl-4 col-sm-4 pt-2">
                <div className="mb-3" >
                    <label htmlFor={labelName} className="form-label text-primary">{labelName}{/* <span className="required">*</span> */}</label>
                </div>
            </div>
            <div className="col-xl-8 col-sm-8">
                <div className="mb-3">
                    <LocalizationProvider dateAdapter={AdapterDayjs} className='p-0 m-0'>
                        <DemoContainer className='p-0 m-0'
                            components={[
                                'DateTimePicker',
                                'MobileDateTimePicker',
                                'DesktopDateTimePicker',
                                'StaticDateTimePicker',
                            ]}
                        >
                            <DemoItem >
                                <MobileDateTimePicker
                                    value={selectedDate}
                                    format='DD/MM/YYYY  hh:mm A'

                                    onChange={(date) => {
                                        if (date && date.isValid()) {
                                            // // const date = '2024-07-15T09:44:57.307Z';                                            
                                            // const date2 = new Date(date);                                         
                                            // const utcOffset = 6.5 * 60 * 60 * 1000;                                     
                                            // const adjustedDate = new Date(date2.getTime() - utcOffset);
                                            // console.log(adjustedDate.toISOString()); // Outputs the adjusted Date object




                                            handleChangeCallBack(inputName, date.toISOString()); // ISO format for database
                                        }
                                    }}

                                    ampm={true}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>




                    {/* <DatePicker
                        className="form-control full-width full-width-container .react-datepicker-wrapper"
                        selected={selectedDate}
                        showTimeSelect
                        timeFormat="hh:mm:aa"
                        timeIntervals={15}
                        timeCaption="Time"
                        onChange={(date) => {
                            const istDate = new Date(date.getTime() + (6.5 * 60 * 60 * 1000)); // Adjust for IST (UTC+5:30)

                            console.log(format(istDate, "yyyy-MM-dd'T'hh:mm:ss.SSSxxx"))
                            handleChangeCallBack(inputName, format(istDate, "yyyy-MM-dd'T'hh:mm:ss.SSSxxx"));
                        }}
                        dateFormat="dd/MM/yyyy , hh:mm:aa"
                        yearDropdownItemNumber={20}
                        showYearDropdown
                        scrollableYearDropdown
                        todayButton="Today"
                        timeZone="Asia/Kolkata"
                    /> */}

                </div>

            </div>
        </>
    );
}

export default DateInput;




















































// import React from 'react';
// import { format } from 'date-fns';

// import DatePicker from "react-datepicker";
// const DateInput = ({ selected, handleChangeCallBack, labelName, inputName }) => {
//     console.log(new Date(selected))

//     const selectedDate = new Date(selected); // Parse the selected value as a Date object
//     // selectedDate.setUTCHours(selectedDate.getUTCHours() + 6); // Adjust for India time zone (UTC+5)
//     // selectedDate.setUTCMinutes(selectedDate.getUTCMinutes() + 30); // Adjust for India time zone (additional 30 minutes)

//     return (
//         <>
//             <div className="col-xl-4 col-sm-4 pt-2">
//                 <div className="mb-3" >
//                     <label htmlFor={labelName} className="form-label text-primary">{labelName}{/* <span className="required">*</span> */}</label>
//                 </div>
//             </div>
//             <div className="col-xl-8 col-sm-8">
//                 <div className="mb-3">
//                     <DatePicker
//                         className="form-control full-width full-width-container .react-datepicker-wrapper"
//                         selected={selectedDate}
//                         showTimeSelect
//                         timeFormat="hh:mm:aa"
//                         timeIntervals={15}
//                         timeCaption="Time"
//                         onChange={(date) => {
//                             const istDate = new Date(date.getTime() + (6.5 * 60 * 60 * 1000)); // Adjust for IST (UTC+5:30)
//                             handleChangeCallBack(inputName, format(istDate, "yyyy-MM-dd'T'hh:mm:ss.SSSxxx"));
//                         }}
//                         dateFormat="dd/MM/yyyy , hh:mm:aa"
//                         yearDropdownItemNumber={20}
//                         showYearDropdown
//                         scrollableYearDropdown
//                         todayButton="Today"
//                         timeZone="Asia/Kolkata"
//                     />
//                 </div>

//             </div>
//         </>
//     );
// }

// export default DateInput;
