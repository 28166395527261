import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, Badge } from 'react-bootstrap';
import TopicModal from './TopicModal';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../../http/http-config';
import { Pagination } from '@mui/material';
import '../../common-css/Table.css'
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, showTableResponse, sortInAlphabeticalOrder } from '../../common-helpers/commonServices';
import SearchBox from '../../Common-Components/SearchBox';
import ProdutsDetailsTabsAtTop from '../../Common-Components/ProdutsDetailsTabsAtTop';
import { getCourses, getSubjects } from '../../CommonHelper';
import Select from 'react-select';
import { setDropDownStyle } from '../../CommonHelper';
import { toast } from 'react-toastify';
import TopicBatchDateModal from './TopicBatchDateModal';



const TopicStartDate = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [topics, setTopics] = useState([]);
    const [courses, setCourses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({ courseTitle: "Select Course" });
    const [selectedBatch, setSelectedBatch] = useState({ batch: "Select Batch" });
    const [isLoading, setIsLoading] = useState(false);




    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(topics);
    //set Table data
    useEffect(() => {
        getCourses().then((res) => {
            res.unshift({ courseTitle: "Select Course" })
            setCourses(res)
        })
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(BACKEND_SERVER_URL + '/getCourseBatches/' + selectedCourse.courseId);
                let batches = response?.data?.associatedBatches.map(obj => ({ batch: obj.BatchName, batchId: obj.Sn }));
                batches.unshift({ batch: "Select Subject" });
                setBatches(batches);
            } catch (error) {
                console.log(error)
                toast.error(error.response.data.message)
            }
        }
        if (selectedCourse.courseId) fetchData();

    }, [selectedCourse])

    const pageSize = 20;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)


    const selectCourseHandler = (selectedCourse) => {
        if (selectedCourse.courseTitle == 'Select Course') {
            setSelectedCourse({ courseTitle: "Select Course" });
            return;
        }
        setSelectedCourse(selectedCourse);
    }

    const selectSubjectHandler = (selectedSubject) => {
        if (selectedSubject.batch == 'Select Batch') {
            setSelectedBatch({ batch: "Select Batch" });
            return;
        }
        setSelectedBatch(selectedSubject);
    }
    console.log(checked);
    const handleShowLessons = async () => {
        if (selectedBatch?.batchId && selectedCourse.courseId) {
            try {
                const response = await axios.get(BACKEND_SERVER_URL + '/getLessonDates/' + selectedCourse.courseId + '/' + selectedBatch.batchId);
                const formatedData = response?.data?.lessonDates?.map(obj => ({ sn: obj.Sn, sectionId: obj.SectionID, title: obj.Title, subjectId: obj.SubjectID, subject: obj.Subject, courseId: obj.CourseID, date: obj.finalStartDate, batchId: selectedBatch?.batchId }))
                setTopics(formatedData)
                setChecked(formatedData)
            } catch (error) {
                console.log(error)
                toast.error(error.response.data.message)
            }
        } else {
            toast.error("Please select course and batch");
        }
    }






    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className=' col-10  d-flex justify-content-around'>
                                    <div className="col-5">
                                        <div className="basic-dropdown">
                                            <Select
                                                value={{ value: selectedCourse, label: selectedCourse.courseTitle }}
                                                onChange={(selectedOption) => selectCourseHandler(selectedOption.value)}
                                                options={courses?.map((course) => ({ value: course, label: course.courseTitle }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="basic-dropdown ms-1">
                                            <Select
                                                value={{ value: selectedBatch, label: selectedBatch.batch }}
                                                onChange={(selectedOption) => selectSubjectHandler(selectedOption.value)}
                                                options={batches?.map((subject) => ({ value: subject, label: subject.batch }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-dark btn-xs ms-2 " onClick={handleShowLessons}> Show Lessons</button>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div className='dataTables_info text-dark'>
                                    Records: {checked.length}
                                </div>
                                {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                                    id="example-student_wrapper">
                                    <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                                </div> : null}
                            </div>
                            <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                    <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                                        <thead style={{ backgroundColor: "#cccccc" }}>
                                            <tr className='text-center text-dark border border-left'>
                                                <th className=' custom-table-header  p-2'>SN</th>
                                                <th className=' text-start custom-table-header  p-2' >LESSON TITLE</th>
                                                <th className=' text-start custom-table-header  p-2' >SUBJECT</th>
                                                <th className='custom-table-header  p-2' >BATCH ID</th>
                                                <th className='p-2 custom-table-header '>PUBLISH DATE</th>
                                                <th className='custom-table-header  p-2' >OPERATION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.length > 0 ? records.map((item, ind) => (
                                                <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                                    <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0" > {item.sn}</h6></td>


                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <div className="d-flex justify-content-between ">
                                                            <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.title}

                                                            </h6>
                                                        </div>
                                                    </td>

                                                    <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                                        <h6 className="mb-0 ">
                                                            {item.subject}
                                                        </h6>
                                                    </td>
                                                    <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "85px" }}>
                                                        <h6 className="mb-0 ">
                                                            {item?.batchId}
                                                        </h6>
                                                    </td>

                                                    <td className='text-center date-column-width  p-1' style={{ border: "1px solid #cccccc", width: "85px" }}>
                                                        <h6 className="mb-0">
                                                            {new Date(item?.date)?.toLocaleDateString('en-GB', {
                                                                day: '2-digit',
                                                                month: 'short',
                                                                year: 'numeric',
                                                            })}
                                                        </h6>
                                                    </td>

                                                    <td className='p-1 text-center cursor-pointer' style={{ border: "1px solid #cccccc", width: "65px" }} onClick={() => childRef.current.openModal(item)} >
                                                        <Badge bg="" className='badge-primary light badge-md ms-2 cursor-pointer pe-3 ps-3'>
                                                            Edit
                                                        </Badge>

                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="9" className="text-center">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                            {isLoading ? <Spinner animation="grow" variant="info" /> :
                                                                <h3>Please Select Course and Batch</h3>
                                                            }

                                                        </div>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TopicBatchDateModal ref={childRef} handleShowLessons={handleShowLessons} />
        </>
    )
}

export default TopicStartDate;

